html,
body {
  padding: 0 !important;
  margin: 0;
  font-family: 'Heebo', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  font-size: 62.5%; /* now 1rem = 10px */
  max-width: 100%;
  overflow-x: hidden !important;
  background-color: #f6f7fb;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.muirtl-cypn22-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #1f2933 !important;
  z-index: 0 !important;
}
/* Scroolbar fix */

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #cbd2d9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background-color: #cbd2d9;
}

/* Calender Styles */

.rdrDays {
  flex-direction: row-reverse !important;
}
.rdrWeekDays {
  flex-direction: row-reverse !important;
}

.rdrMonth {
  border: none !important;
  padding: 0 2rem 1.666em 2rem !important;
}

.rdrCalendarWrapper {
  background-color: #cbd2d9 !important;
  border-radius: 9px !important;
  overflow: hidden !important;
}

.rdrMonthAndYearWrapper {
  display: flex !important;
  flex-direction: row-reverse !important;
  padding: 0 17px !important;
}

.rdrNextButton i {
  margin: 0 !important;
  transform: translate(-3px, 0px) !important;
}

.rdrSelected {
  color: transparent !important;
  background-color: #7b8795 !important;
}

/* .rdrDay:focus {
  background-color: #7b8795 !important;
  outline: none;
  border-radius: 20px !important;
  color: white !important;
} */

/* .rdrDayEndPreview {
  color: #7b8795 !important;
  background-color: #7b8795 !important;
} */

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: black !important;
}

.rdrDayDisabled {
  background-color: #cbd2d9 !important;
}

.rdrDay:focus {
  color: #7b8795 !important ;
}
.rdrDayEndPreview {
  color: #7b8795 !important ;
}

.rdrDayStartPreview {
  color: #7b8795 !important ;
}

.rdrDay {
  color: #7b8795 !important ;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: none !important;
}

.rdrMonthAndYearPickers select {
  border: 0.65723px solid #323e4a !important;
}

.rdrNextPrevButton,
.rdrPprevButton {
  background: none !important;
}
.rdrNextPrevButton:hover,
.rdrPprevButton:hover {
  background: none !important;
}

.rdrPprevButton i {
  border-width: 14px 6px !important;
}

.rdrNextButton i {
  border-width: 14px 6px !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: none !important;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 300 !important;
}
