.phoneVerificationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Heebo";
  font-style: normal;
  height: 100vh;
  width: 100vw;

  .phoneVerification {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 500px;
    height: 450px;
    background: #ffffff;
    border-radius: 14px;
    position: relative;

    .input {
      box-sizing: border-box;
      border-color: #7b8795;
      border-radius: 4px;
      width: 300px;
      height: 42px;
    }

    .generalErrors {
      color: red;
      font-size: 12px;
      margin-bottom: 20px;
    }

    .button {
      width: 235px;
    }
  }
}
