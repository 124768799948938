.loader {
  background-color: rgba($color: #1f2933, $alpha: 0.6);
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

.progress {
  color: #ffffff;
}
