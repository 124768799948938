.button {
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1f2a33 !important;
  border: none !important;
  cursor: pointer;

  .child {
    font-family: "Heebo", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #f6f7fb;
  }

  .loader {
    color: #f6f7fb !important;
    height: 2.4rem !important;
    width: 2.4rem !important;

    svg {
      circle {
        stroke-width: 1px !important;
      }
    }
  }
}

.disabled {
  background-color: #cbd2d9 !important;
  color: #f6f7fb !important;
}
